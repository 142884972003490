<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import dayjs from 'dayjs'

import { useEntranceStore } from 'store/entrance';
const entranceStore = useEntranceStore()

import { useEventTicket } from 'store/event-ticket';
const eventTicketStore = useEventTicket()

import { useRouter } from "vue-router";
const router = useRouter();

import TablePagination from 'views/components/TablePagination.vue';

onMounted(async () => {
  fetchData()
})

const fetchData = async () => {
  $root.overlay.loading = true
  await entranceStore.getItems()
  $root.overlay.loading = false
}

const doSearch = () => {
  if (entranceStore.filter.type == 'event' && eventTicketStore.groups.length == 0) {
    eventTicketStore.fetchGroups()
  }
  //reset pagination
  entranceStore.filter.page = 1
  fetchData()
}

const showDetail = (val) => {
  entranceStore.item = val
  router.push({ path: `/entrance/detail` });
}
const changePagination = () => {
  fetchData()
}

const handleDateFrom = (val) => {
  entranceStore.filter.from = val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
}
const handleDateTo = (val) => {
  entranceStore.filter.to = val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
}

const format = (date) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm')
}

</script>
<template>
  <div class="w-full bg-white rounded-md p-4 flex flex-col gap-2">

    <div class="flex gap-2 justify-between">
      <div class="flex flex-row gap-1 items-center">
        <select v-model="entranceStore.filter.type" class="px-4 h-8 border border-gray-300 rounded"
          @change="doSearch()">
          <option value="">-- タイプ --</option>
          <template v-for="(group, key) in entranceStore.arrType">
            <option :value="key">{{ group }}</option>
          </template>
        </select>

        <template v-if="entranceStore.filter.type == 'event'">
          <select v-model="entranceStore.filter.group" class="px-4 h-8 border border-gray-300 rounded"
            @change="doSearch()">
            <option value="">-- グループ --</option>
            <template v-for="group in eventTicketStore.groups">
              <option :value="group.id">{{ group.name }}</option>
            </template>
          </select>
        </template>

        <div>
          <VueDatePicker v-model="entranceStore.filter.from" time-picker-inline :format="format" locale="ja" input-class-name="h-8"
            cancelText="キャンセル" selectText="選択" placeholder="----/--/-- --:--" @update:model-value="handleDateFrom">
          </VueDatePicker>
        </div>
        <div>から</div>
        <div>
          <VueDatePicker v-model="entranceStore.filter.to" time-picker-inline :format="format" locale="ja" input-class-name="h-8"
            cancelText="キャンセル" selectText="選択" placeholder="----/--/-- --:--" @update:model-value="handleDateTo">
          </VueDatePicker>
        </div>

        <input type="search" class="px-2 py-1 border border-gray-200 rounded w-56 h-8 focus:border-none"
          v-model="entranceStore.filter.text" @keydown.enter="doSearch()" placeholder="電話番号、クラブ名で検索" />
        <!-- button search -->
        <button
          class="px-4 py-1 h-8 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
          @click.prevent="doSearch">
          <MagnifyingGlassIcon class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
          検索
        </button>
      </div>
      <!-- <div>
        report
      </div> -->
    </div>

    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <th v-for="header in entranceStore.headers" class="font-normal p-2"
            :class="header.field == 'count' ? 'text-center' : 'text-left'" :style="`width:${header.width};`">
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="entranceStore.items.length" v-for="(item, index) in entranceStore.items">
          <tr class="border-b border-b-gray-200" :class="{
          'bg-[#FDFAE2]': item.checkout_time,
          'bg-[#E9FEEE]': !item.checkout_time,
        }">
            <td class="p-2 font-bold text-pblue cursor-pointer hover:opacity-80 hover:underline"
              @click.prevent="showDetail(item)">
              {{ item.name }}
            </td>
            <td class="p-2">{{ item.type_name }}</td>
            <td class="p-2">{{ item.phone }}</td>
            <td class="p-2 text-center">{{ item.count }}</td>
            <td class="p-2">{{ item.checkin_time }}</td>
            <td class="p-2">{{ item.checkout_time }}</td>
            <td class="p-2 max-w-40 truncate text-ellipsis">
              {{ item.reason }}
            </td>
          </tr>
        </template>
        <tr v-else-if="!$root.overlay.loading">
          <td class="p-2 text-center text-xs text-grey" :colspan="entranceStore.headers.length">
            データがありません
          </td>
        </tr>
      </tbody>
    </table>

    <TablePagination :onPageChange="changePagination" :total="entranceStore.pagination.total"
      v-model:currentPage="entranceStore.filter.page" :perPage="entranceStore.pagination.perPage" />
  </div>
</template>
