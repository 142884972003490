<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { ChevronRightIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../js/store/club';
import ClubItemMemberAddGuest from './ClubItemMemberAddGuest.vue';
import TextHelper from '../../js/helper/TextHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const clubItemMemberAddGuest = ref(null)
const isSearching = ref(false)

const isOpen = ref(false)
const open = () => {
  clubStore.searchText = ''
  clubStore.members = []
  clubStore.searched = false

  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const searchHandler = async () => {
  isSearching.value = true
  try {
    await clubStore.searchMembers()
  } catch (error) {
    console.error('Error searching:', error);
  }
  isSearching.value = false
}

const addGuest = async () => {
  close()

  clubItemMemberAddGuest.value.open()
}

const addMember = async (member) => {
  $root.overlay.loading = true
  try {
    const response = await clubStore.addMemberExisting(member)
    if (response.status === 'error') {
      $root.push.error(response.message)
    } else {
      $root.push.success('メンバーを追加しました')
    }
  } catch (error) {
    $root.push.error('メンバーの追加に失敗しました')
  }
  $root.overlay.loading = false
}

const getNameText = (mem) => {
  if (mem.name) {
    return mem.name
  }

  if (mem.kanji_name) {
    return mem.kanji_name + (mem.romaji_name ? '<br>' + mem.romaji_name : '')
  }

  if (mem.romaji_name) {
    return mem.romaji_name
  }

  return ''
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadow-xl transition-all rounded-xl p-4"
            >
              <!-- add member to club #A -->
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center gap-2 mb-4"
              >
                <div class="px-2 py-1 rounded-md bg-gray-200">{{ clubStore.item.name }}</div>
                <ChevronRightIcon class="w-4 h-4 text-gray-400" />
                メンバー追加
              </DialogTitle>
              <div class="flex flex-col gap-2">
                <!-- search by phone or student id only -->
                <div class="flex items-center gap-1">
                  <div class="grow relative">
                    <MagnifyingGlassIcon class="w-5 h-5 text-gray-400 absolute top-1/2 left-2 transform -translate-y-1/2" />
                    <input 
                      v-model="clubStore.searchText"
                      type="text" 
                      class="w-full pl-8 pr-2 py-2 border border-gray-200 rounded" 
                      placeholder="電話番号または学籍番号で検索"
                      :disabled="isSearching"
                      @keyup.enter="searchHandler"
                    />
                  </div>
                  <div class="flex-none w-fit">
                    <!-- button search -->
                    <button 
                      type="button"
                      class="px-4 py-2 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition-all duration-300 delay-75 ease-in-out"
                      :disabled="isSearching"
                      @click.prevent="searchHandler"
                    >
                      <svg v-show="isSearching" class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      検索
                    </button>
                  </div>
                </div>

                <!-- option auto generate qrcode after add member to club -->
                <div v-if="clubStore.members && clubStore.members.length" class="flex items-center gap-1 cursor-pointer">
                  <input 
                    type="checkbox" 
                    class="form-checkbox rounded text-primary w-5 h-5" 
                    id="auto-generate-qrcode"
                    v-model="clubStore.autoGenerateQrcode"
                  />
                  <label for="auto-generate-qrcode">メンバー追加後、QRコードを自動生成する</label>
                </div>

                <div v-if="clubStore.members && clubStore.members.length" class="flex w-full flex-col">
                  <table class="table w-full">
                    <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
                      <tr>
                        <th>生徒ID</th>
                        <th class="p-1">名前</th>
                        <th class="p-1">性別</th>
                        <th class="p-1">電話番号</th>
                        <th class="p-1">ゲスト</th>
                        <th class="p-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="mem in clubStore.members">
                        <tr
                          :class="{
                            'bg-gray-100': !mem.parent || mem.parent.length == 0
                          }"
                        >
                          <td>
                            <span v-if="mem.type === 'student'">
                              {{ mem.student_code }}
                            </span>
                          </td>
                          <td>
                            <span v-html="mem.name"></span>
                          </td>
                          <td>{{ TextHelper.getGenderJp(mem.gender) }}</td>
                          <td>{{ mem.phone }}</td>
                          <td>
                            {{ 
                              mem.type === 'guest' 
                                ? 'ゲスト'
                                : (
                                  mem.type === 'student' 
                                    ? '学生' 
                                    : '保護者'
                                )  
                            }}
                          </td>
                          <td class="text-right pr-1">
                            <!-- Add member to club in japanese -->
                              <button 
                                type="button"
                                class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white"
                                title="クラブに追加"
                                @click.prevent="addMember(mem)"
                              >
                                <PlusIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                              </button>
                          </td>
                        </tr>
                        <tr 
                          v-if="mem.parent && mem.parent.length"
                          class="border-b border-b-gray-200"
                        >
                          <td class="pl-6" colspan="6">
                            <table class="table w-full">
                              <thead class="border-t border-b border-gray-200 bg-gray-100 text-xs">
                                <tr>
                                  <th>名前</th>
                                  <th>電話番号</th>
                                  <th>関係</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="parent in mem.parent">
                                  <td><span v-html="parent.name"></span></td>
                                  <td>{{ parent.phone }}</td>
                                  <td>{{ TextHelper.getTextRelationship(parent.relationship) }}</td>
                                  <td class="text-right pr-1">
                                    <button 
                                      type="button"
                                      class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white"
                                      title="クラブに追加"
                                      @click.prevent="addMember(parent)"
                                    >
                                      <PlusIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                                    </button>          
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr 
                          v-if="mem.childs && mem.childs.length"
                          class="border-b border-b-gray-200"
                        >
                          <td class="pl-6" colspan="6">
                            <table class="table w-full">
                              <thead class="border-t border-b border-gray-200 bg-gray-100 text-xs">
                                <tr>
                                  <th>生徒ID</th>
                                  <th>名前</th>
                                  <th>性別</th>
                                  <th>ゲスト</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="child in mem.childs">
                                  <td>{{ child.student_code }}</td>
                                  <td><span v-html="child.name"></span></td>
                                  <td>{{ child.gender }}</td>
                                  <td>学生</td>
                                  <td class="text-right pr-1">
                                    <button 
                                      type="button"
                                      class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white"
                                      title="クラブに追加"
                                      @click.prevent="addMember(child)"
                                    >
                                      <PlusIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                                    </button>          
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <!-- click + button to add member to club or click to ゲスト追加 to add new guest if search not found -->
                  <div class="text-xs text-blue-500 mt-6 font-semibold">
                    ※ メンバー追加ボタンをクリックすると、メンバーがクラブに追加されます
                  </div>
                </div>
                <div v-else>
                  <div v-if="clubStore.searched" class="text-center text-gray-400">検索結果がありません</div>
                </div>

                <div class="flex justify-center mt-6 gap-4">
                  <!-- cancel -->
                  <button 
                    class="px-4 py-2 bg-white border rounded hover:opacity-80 group flex items-center gap-1 transition-all duration-300 delay-75 ease-in-out"
                    @click.prevent="close"
                  >
                    キャンセル
                  </button>

                  <!-- add guest -->
                  <button 
                    class="px-4 py-2 bg-tertiary rounded hover:opacity-80 group flex items-center gap-1 transition-all duration-300 delay-75 ease-in-out"
                    @click.prevent="addGuest"
                  >
                    <PlusIcon class="w-5 h-5 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                    ゲスト追加
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <ClubItemMemberAddGuest ref="clubItemMemberAddGuest" />
</template>
