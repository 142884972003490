<script setup>
import { getCurrentInstance } from 'vue'
import { PencilIcon, XMarkIcon, } from '@heroicons/vue/24/outline';
import { useResource } from '../../../js/store/resource';
import TableResourcesHeader from './TableResourcesHeader.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const resourceStore = useResource()

const removeItem = (id) => {
  // get name of item
  const item = resourceStore.items.find(resource => resource.id === parseInt(id))

  // confirm delete
  const c = confirm(`「${item?.name}」を削除してもよろしいですか？`)
  if (!c) return

  $root.overlay.loading = true

  // TODO: remove item

  $root.overlay.loading = false
}
</script>
<template>
  <!-- resources -->
  <div class="flex flex-col relative">
    <TableResourcesHeader @add="$emit('add')" />
    <div class="flex flex-col">
      <div v-for="resource in resourceStore.searchItems" class="p-1 border-b border-b-gray-200 last:border-b-0 flex w-full items-center">
        <div class="grow overflow-hidden">
          <div class="flex items-center justify-between">
            <div class="flex flex-col overflow-hidden">
              <!-- name -->
              <a class="text-sm truncate max-w-40">
                  {{ resource.name }}
                </a>
              <!-- location -->
                <a class="truncate max-w-40 text-xs text-gray-500">
                  ({{ resource.quantity }} {{ resource.unit }})
                </a>
            </div>
            <div class="px-2 text-xs text-gray-500 overflow-hidden">
              <!-- Resource manager -->
              <a :title="resource.manager" href="javascript:void(0)" class="flex items-center p-px truncate">
                {{ resource.manager_name}}
              </a>
              <a :title="resource.manager" href="javascript:void(0)" class="flex items-center p-px truncate">
                {{ resource.manager_phone}}
              </a>
            </div>
          </div>
        </div>
        <div class="w-fit flex-none">
          <div class="flex items-center gap-2">
            <button title="編集" class="group" @click.prevent="$emit('add', JSON.parse(JSON.stringify(resource)))">
              <PencilIcon class="w-5 h-5 text-primary group-hover:scale-110 transition-all duration-100 delay-75 ease-in-out" />
            </button>
            <!-- <button title="削除" class="group" @click.prevent="removeItem(resource.id)">
              <XMarkIcon class="w-6 h-6 text-error group-hover:scale-110 transition-all duration-100 delay-75 ease-in-out" />
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
