<script setup>
import {ref, getCurrentInstance } from 'vue'
import {ChevronRightIcon } from '@heroicons/vue/24/outline';
import { useDatabaseStore } from '../../js/store/database';
import QrItemModal from './QrItemModal.vue';
import TablePagination from './TablePagination.vue';
import TextHelper from '../../js/helper/TextHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const databaseStore = useDatabaseStore()
const refQrModal = ref(null)
</script>
<template>
  <div class="w-full flex flex-col gap-2">
    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <th class="p-2 w-6"></th>
          <th v-for="header in databaseStore.headers" class="font-normal p-2 text-left">{{ header.text }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="databaseStore.items.length">
          <template v-for="item in databaseStore.items">
            <tr 
              class="transition-all delay-75 duration-300 ease-in-out"
              :class="{
                'border-b border-b-gray-200': !item.open || ((!item.families || item.families.length === 0) && (!item.staff || item.staff.length === 0)),
                'bg-yellow': item.open && ((item.families && item.families.length) || (item.staff && item.staff.length)),
              }"
            >
              <td class="p-2">
                <ChevronRightIcon 
                  v-if="(item.families && item.families.length) || (item.staff && item.staff.length)"
                  class=" text-gray-500 cursor-pointer transition-all delay-75 duration-300 ease-in-out"
                  :class="{
                    'rotate-90 text-gray-800 w-5 h-5': item.open,
                    'rotate-0 w-5 h-5': !item.open,
                  }"
                  @click.prevent="item.open = !item.open" 
                />
              </td>
              <td class="p-2">{{ item.id }}</td>
              <td class="p-2">{{ item.student_code }}</td>
              <td class="p-2 text-blue-500 font-semibold hover:underline hover:opacity-80">
                <router-link :to="`/database/${item.id}`">
                  {{ item.kanji_name }} <br> {{ item.romaji_name }}
                </router-link>
              </td>
              <td class="p-2">{{ item.school_level }}</td>
              <td class="p-2">{{ item.grade }}</td>
              <td class="p-2">{{ item.class_name }}</td>
              <td class="p-2">{{ item.address }}</td>
              <td class="p-2"><img v-if="item.qr_code_url" @click.prevent="refQrModal.open(item.qr_code_url)" :src="item.qr_code_url" class="w-12 h-12" loading="lazy" /></td>
            </tr>
            <tr 
              v-show="item.open && ((item.families && item.families.length) || (item.staff && item.staff.length))"
              class="border-b border-b-gray-200"
            >
              <td :colspan="databaseStore.headers.length + 1">
                <div class="flex justify-center px-16 pb-6 w-full">
                  <table class="w-full table">
                    <thead class="border-b border-b-gray-200 bg-gray-100 text-xs">
                      <tr>
                        <th 
                          v-for="childHeader in databaseStore.childrenHeaders" 
                          class="font-normal p-2 text-left"
                        >{{ childHeader.text }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="item.staff">
                        <tr v-for="child in item.staff" class="border-b border-b-gray-200 last:border-b-0">
                          <td class="p-2">{{ child.kanji_name }}<br>{{ child.romaji_name }}</td>
                          <td class="p-2">
                            {{ child.phone }}<br>
                            <div class="flex items-center gap-2">
                              <span class="px-2 py-px bg-blue-500 rounded-full text-xs text-white font-semibold">
                                学校職員 <span v-if="child.staff_position && child.staff_position.name">
                                ({{ child.staff_position.name }})
                              </span>
                              </span>
                            </div>
                          </td>
                          <td class="p-2">{{ child.email }}</td>
                          <td class="p-2">{{ child.gender == '男性' ? '父' : child.relationship == '女性' ? '母' : '他' }}</td>
                          <td class="p-2"><img v-if="child.qr_code_url" @click.prevent="refQrModal.open(child.qr_code_url)" :src="child.qr_code_url" class="w-12 h-12" loading="lazy" /></td>
                        </tr>
                      </template>
                      <template v-if="item.families">
                        <tr v-for="child in item.families" class="border-b border-b-gray-200 last:border-b-0">
                          <td class="p-2">{{ child.kanji_name }}<br>{{ child.romaji_name }}</td>
                          <td class="p-2">{{ child.phone }}</td>
                          <td class="p-2">{{ child.email }}</td>
                          <td class="p-2">{{ TextHelper.getTextRelationship(child.relationship) }}</td>
                          <td class="p-2"><img v-if="child.qr_code_url" @click.prevent="refQrModal.open(child.qr_code_url)" :src="child.qr_code_url" class="w-12 h-12" loading="lazy" /></td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <tr v-else-if="!$root.overlay.loading">
          <td class="p-2 text-center text-grey text-xs" :colspan="databaseStore.headers.length">データがありません</td>
        </tr>
      </tbody>
    </table>

    <TablePagination :total="databaseStore.total" v-model:currentPage="databaseStore.currentPage" :perPage="databaseStore.perPage" :onPageChange="databaseStore.getItems" />
  </div>
  <QrItemModal ref="refQrModal" />
</template>
