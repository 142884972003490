<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { useStaff } from 'store/staff';
const staffStore = useStaff()

import StaffItemModel from './StaffItemModel.vue';

import QrItemModal from 'views/components/QrItemModal.vue';
const refQrModal = ref(null)

import ConfirmModal from 'views/components/ConfirmModal.vue';
const confirmDeleteModal = ref(null)

import TablePagination from 'views/components/TablePagination.vue';

onMounted(async () => {
    fetchData()
})

const fetchData = async () => {
    $root.overlay.loading = true
    await staffStore.getItems()
    await staffStore.fetchPositions()
    $root.overlay.loading = false
}
const refStaffModel = ref(null)
const showModel = (item) => {
    if (item.id > 0) {
        staffStore.item = JSON.parse(JSON.stringify(item))
    }
    else {
        staffStore.item = JSON.parse(JSON.stringify(staffStore.intItem))
    }
    refStaffModel.value.open()
}

import ImportModal from './ImportModal.vue';
const refImportModal = ref(null)

const doSearch = () => {
    fetchData()
}

const deleteItem = ref(0)
const deleteStaff = async () => {
    $root.overlay.loading = true
    try {
        const response = await axios.delete(`/api/staff-manager/${deleteItem.value}`)
        if (response.status == 200) {
            $root.push.success('正常に削除されました')
            fetchData()
        }
        else {
            $root.push.error('エラーが発生しました')
        }
        
        $root.overlay.loading = false
    } catch (error) {
        $root.push.error('エラーが発生しました')
        $root.overlay.loading = false
    }
}
</script>
<template>
    <div class="w-full bg-white rounded-md p-4 flex flex-col gap-2">
        <div class="flex justify-between">
            <button class="px-4 h-8 bg-primary shadow text-white rounded hover:opacity-80"
                @click.prevent="showModel({})">
                新規登録
            </button>
            <div class="flex gap-2">
                <button class="px-4 h-8 bg-sky-500 shadow text-white rounded hover:opacity-80"
                    @click.prevent="refImportModal.open()">
                    インポート
                </button>
                <button class="px-4 h-8 bg-orange-700 shadow text-white rounded hover:opacity-80">
                    <a href="sample/sample_staff_import.xlsx" download="sample_staff_import.xlsx">サンプルファイル</a>
                </button>
            </div>
        </div>
        <hr>
        <div class="flex justify-start gap-2">
            <select v-model="staffStore.filter.position" class="px-4 h-8 border border-gray-300 rounded"
                @change="doSearch()">
                <option value="">-- 役割 --</option>
                <template v-for="position in staffStore.positions">
                    <option :value="position.id">{{ position.name }}</option>
                </template>
            </select>

            <input type="search" class="px-4 h-8 border border-gray-200 rounded w-56 focus:border-none"
                v-model="staffStore.filter.text" @keydown.enter="doSearch()" placeholder="電話番号、名前等で検索" />

            <button
                class="px-4 h-8 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
                @click.prevent="doSearch()">
                <MagnifyingGlassIcon
                    class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />検索
            </button>
        </div>

        <table class="w-full table">
            <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
                <tr>
                    <th v-for="header in staffStore.headers" class="font-normal p-2"
                        :class="header.field == 'count' ? 'text-center' : 'text-left'"
                        :style="`width:${header.width};`">
                        {{ header.text }}
                    </th>
                    <th class="font-normal p-2">操作</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="staffStore.items.length">
                    <tr v-for="(item, index) in staffStore.items" class="border-b border-b-gray-200">
                        <td class="p-2">{{ item.id }}</td>
                        <td
                            class="p-2 text-blue-500 font-semibold hover:underline hover:opacity-80 hover:cursor-pointer">
                            <div @click.prevent="showModel(item)">
                                {{ item.kanji_name }} <br> {{ item.romaji_name }}
                            </div>
                        </td>
                        <td class="p-2">
                            {{ staffStore.positions.find(pos => pos.id == item.staff_position_id)?.name }}
                        </td>
                        <td class="p-2">{{ item.gender }}</td>
                        <td class="p-2">{{ item.email }}</td>
                        <td class="p-2">{{ item.phone }}</td>
                        <td class="p-2">{{ item.date_of_birth }}</td>
                        <td class="p-2">{{ item.address }}</td>
                        <td class="p-2">{{ item.status == 1 ? '有効' : '無効' }}</td>
                        <td class="p-2 hover:cursor-pointer">
                            <img v-if="item.qr_code_url" @click.prevent="refQrModal.open(item.qr_code_url)"
                                :src="item.qr_code_url" class="w-12 h-12" loading="lazy" />
                        </td>
                        <td class="p-2">
                            <div class="flex gap-2 items-center justify-center">
                                <button type="button" class="p-1.5 text-error group hover:opacity-80"
                                    @click.prevent="deleteItem=item.id; confirmDeleteModal.open();">
                                    <TrashIcon
                                        class="w-5 h-5 group-hover:scale-125 transition-all duration-300 delay-75 ease-in-out cursor-pointer" />
                                </button>
                            </div>
                        </td>
                        <td class="flex items-center justify-center">

                        </td>
                    </tr>
                </template>
                <tr v-else-if="!$root.overlay.loading">
                    <td class="p-2 text-center text-xs text-grey" :colspan="staffStore.headers.length + 1">
                        データがありません
                    </td>
                </tr>
            </tbody>
        </table>

        <TablePagination :onPageChange="fetchData" :total="staffStore.pagination.total"
            v-model:currentPage="staffStore.filter.page" :perPage="staffStore.pagination.perPage" />

    </div>
    <StaffItemModel ref="refStaffModel" />
    <QrItemModal ref="refQrModal" />
    <ImportModal ref="refImportModal" />
    <ConfirmModal ref="confirmDeleteModal" title="職員削除確認" message="この職員を削除しますか？" :confirm="deleteStaff" />
</template>
